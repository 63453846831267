import React, { Component } from 'react';
import styles from './styles.module.css';
import { useLocation } from 'react-router-dom';

class FilterSwitch extends Component {
  render() {
    const {
      options,
      className,
      label,
      value,
      multi,
      simple = false,
    } = this.props;

    let classes = [styles['filter-switch']];
    if (className) classes.push(className);
    if (simple) classes.push(styles['filter-switch__no-label']);

    return (
      <div
        className={classes.join(' ')}
        onClick={() => this.props.isWorkOffMe && this.props.setOffMe()}
      >
        {!simple && (
          <label className={styles['filter-switch__label']}>{label}:</label>
        )}
        <div className={styles['filter-switch__options']}>
          {options.map((item, index) => {
            const classes = [styles['filter-switch__option']];
            const isActive = multi
              ? value.indexOf(item.value) !== -1
              : value === item.value;

            if (isActive) classes.push(styles['filter-switch__option-active']);

            return (
              <div
                className={classes.join(' ')}
                onClick={this.onClick.bind(this, item.value)}
                key={index}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  onClick(value) {
    const { onChange, name } = this.props;
    this.props.isWorkOffMe && this.props.setOffMe();
    if (typeof onChange === 'function') onChange(name, value);
  }
}

export default FilterSwitch;
