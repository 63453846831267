import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { URLS } from '~src/api/urls';
import { getObjects } from '~src/api/get';
import Filters from '~components/Filters';
import FilterContainer from '~contextProviders/Filter';

const FilterStatic = ({ filters = [] }) => {
  const [objects, setObjects] = useState([]);
  
  const fetchObjects = (query = {}) => {
    getObjects({ query }).then(({ data: { items } }) => {
      setObjects(items);
    });
  };

  const updateFilters = debounce((query) => {
    fetchObjects(query);
  }, 100);

  const search = (query) => {
    window.location.href = URLS.old.getSearchUrl(query);
  };
  
  return (
    <FilterContainer
      filters={filters}
      updateFilters={updateFilters}
      onSearch={search}
      shouldUpdateOnMount
    >
      <Filters objects={objects} />
    </FilterContainer>
  )
};

export default FilterStatic;
