import React, { Component } from 'react';
import autobind from 'autobind-decorator';
import { Check } from 'styled-icons/boxicons-regular/Check';
import { CaretDown } from 'styled-icons/boxicons-regular/CaretDown';
import styles from './styles.module.css';

class FilterSelect extends Component {
  state = {
    open: false,
  };

  getSelectClasses() {
    const { className, theme = 'dark' } = this.props;
    let classes = [styles['filter-select']];

    if (className) {
      classes.push(className);
    }
    if (theme && styles['filter-select_' + theme]) {
      classes.push(styles['filter-select_' + theme]);
    }
    if (this.state.open) {
      classes.push(styles['filter-select_open']);
    }

    return classes;
  }

  getLabelText() {
    const { options, value, multi = false } = this.props;

    if ((multi && value.length === options.length) || value.length === 0) {
      return 'Все';
    }
    if (multi && value.length > 1 && value.length !== options.length) {
      return 'Выбрано - ' + value.length;
    }
    if (multi && value.length === 1) {
      return options.filter((item) => item.value === value[0])[0].label;
    }

    const filteredOptions = options.filter((item) => item.value === value);

    return filteredOptions.length ? filteredOptions[0].label : '';
  }

  getOptions() {
    const { options, value, multi = false } = this.props;

    return (
      <div className={styles['filter-select__options']}>
        {options.map((item, index) => {
          const classes = this.props.offDarkMode
            ? [styles['filter-select__option']]
            : [styles['filter-select__option-dark']];
          const isActive = multi
            ? value.indexOf(item.value) !== -1
            : item.value === value;

          if (isActive) classes.push(styles['filter-select__option-active']);

          return (
            <div
              className={classes.join(' ')}
              key={`select-${index}`}
              onClick={this.onSelect.bind(this, item.value)}
            >
              <span>{item.label}</span>
              {isActive && <Check color='#fff' size={20} />}
            </div>
          );
        })}
      </div>
    );
  }

  onSelect(value) {
    const { onChange, name } = this.props;
    if (typeof onChange === 'function') {
      onChange(name, value);
    }
  }

  @autobind
  onToogleOpen() {
    const { open } = this.state;
    this.props.isWorkOffMe && this.props.setOffMe();
    this.setState(() => ({ open: !open }), this.toogleActive);
  }

  @autobind
  toogleActive() {
    if (this.node) {
      const toogleActive = (event) => {
        if (event.target !== this.node) {
          this.setState(() => ({ open: false }));
          window.removeEventListener('click', toogleActive);
        }
      };
      window.addEventListener('click', toogleActive);
    }
  }

  render() {
    const { label, hideLabel = false } = this.props;
    return (
      <div
        className={this.getSelectClasses().join(' ')}
        ref={(node) => (this.node = node)}
        onClick={this.onToogleOpen}
      >
        {!hideLabel && (
          <label
            className={
              this.props.offDarkMode
                ? styles['filter-select__current-label']
                : styles['filter-select__current-label-dark']
            }
          >
            {label}:
          </label>
        )}
        <div className={styles['filter-select__current']}>
          <div
            className={
              this.props.offDarkMode
                ? styles['filter-select__current-labelW']
                : styles['filter-select__current-label-dark']
            }
          >
            {this.getLabelText()}
          </div>
          <CaretDown color='rgb(109,109,109)' size={15} />
        </div>
        {this.getOptions()}
      </div>
    );
  }
}

export default FilterSelect;
