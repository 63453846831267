import fetchJSONP from '~utils/fetchJSONP';
import buildUrl from '~utils/buildUrl';
import { URLS } from '~src/api/urls';
import { GetOptions } from './types';

export default function getFilters({ query }: GetOptions) {
  return fetchJSONP<Record<string, unknown>>(
    buildUrl(URLS.old.getFilter, query),
  );
}
