import React from "react";
import { hydrate } from "react-dom";
import { CookiesProvider } from 'react-cookie';
import { CurrencyContextProvider } from '~contextProviders/CurrencyContextProvider';
import FilterStatic from '~components/Filters/components/FilterStatic';
import defaultState from './default-state.json';

const FilterStaticEntry = ({ config }) => {
  const { filterStatic } = Object.assign({}, defaultState, config);
  return (
    <CookiesProvider>
      <CurrencyContextProvider>
        <FilterStatic filters={filterStatic} />
      </CurrencyContextProvider>
    </CookiesProvider>
  )
};

if (typeof window['RC_APPS'] !== 'object') window['RC_APPS'] = {};

window['RC_APPS']['filter-static'] = (container, widgetProps) => {
  hydrate(FilterStaticEntry(widgetProps), container);
};

if (module.hot) {
  module.hot.accept();
}

