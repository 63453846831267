import fetchJsonp from 'fetch-jsonp';

const DEFAULT_OPTIONS: fetchJsonp.Options = {
  timeout: 15000,
};

const fetchJSONP = <T>(
  url: string,
  customOptions?: fetchJsonp.Options,
): Promise<T> => {
  const requestOptions = { ...DEFAULT_OPTIONS, ...customOptions };
  return fetchJsonp(url, requestOptions)
    .then((res) => res.json())
    .catch((e): never => {
      throw new Error(e.message);
    });
};

export default fetchJSONP;
