import React, { useContext } from 'react';
import get from 'lodash/get';
import FilterSwitch from './components/FilterSwitch';
import FilterSelect from './components/FilterSelect';
import FilterRange from './components/FilterRange';
import grammar from '~constants/grammar';
import { FilterContext } from '~contextProviders/Filter';
import styles from './styles.module.css';

const Filters = ({
  objects,
  currency,
  setOffMe,
  isWorkOfficeCategory = false,
}) => {
  const {
    onSearch,
    getActiveFilters,
    filters,
    changeFilter,
    changeMultipleFilter,
  } = useContext(FilterContext);

  const hasFilters = !!filters.length;
  const activeFilters = getActiveFilters();

  const classes = [styles['filter-list']];
  const filtersToRender = filters.filter((item) => !!activeFilters[item.name]);
  
  const declOfNumTitlePostfix = grammar.offer(objects.length);

  if (!hasFilters) {
    classes.push(styles['filter-list__loader']);
  }

  return (
    <div className={classes}>
      {hasFilters && (
        <div className={styles['filter-list__wrapper']}>
          {filtersToRender.map((item, index) => {
            switch (item.type) {
              case 'switch':
                return (
                  <FilterSwitch
                    className={styles['filter-list__item']}
                    key={`filter-${index}`}
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    value={activeFilters[item.name]}
                    onChange={changeFilter}
                    simple={false}
                    isWorkOffMe={isWorkOfficeCategory}
                    setOffMe={setOffMe}
                  />
                );
              case 'multiSwitch':
                return (
                  <FilterSwitch
                    className={styles['filter-list__item']}
                    key={`filter-${index}`}
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    value={activeFilters[item.name]}
                    onChange={changeMultipleFilter}
                    multi
                    simple={false}
                    isWorkOffMe={isWorkOfficeCategory}
                    setOffMe={setOffMe}
                  />
                );
              case 'select':
                return (
                  <FilterSelect
                    className={styles['filter-list__item']}
                    key={`filter-${index}`}
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    value={activeFilters[item.name]}
                    onChange={changeFilter}
                    simple={''}
                    setOffMe={setOffMe}
                    isWorkOffMe={isWorkOfficeCategory}
                    offDarkMode={true}
                  />
                );
              case 'yesNo':
                return (
                  <FilterSwitch
                    key={`filter-${index}`}
                    className={styles['filter-list__item__small']}
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    value={activeFilters[item.name]}
                    onChange={changeMultipleFilter}
                    multi
                    simple={false}
                    isWorkOffMe={isWorkOfficeCategory}
                    setOffMe={setOffMe}
                  />
                );
              case 'multiSelect':
                return (
                  <FilterSelect
                    className={styles['filter-list__item']}
                    key={`filter-${index}`}
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    value={activeFilters[item.name]}
                    onChange={changeMultipleFilter}
                    multi
                    hideLabel={false}
                    setOffMe={setOffMe}
                    isWorkOffMe={isWorkOfficeCategory}
                    offDarkMode={true}
                  />
                );
              case 'range':
              case 'price': {
                let { from, to, fromOptions, toOptions } = item;
                const objTypeValue = activeFilters.type;

                if (objTypeValue) {
                  from = get(from, objTypeValue, from);
                  to = get(to, objTypeValue, to);
                  fromOptions = Array.isArray(fromOptions)
                    ? fromOptions
                    : fromOptions[objTypeValue];
                  toOptions = Array.isArray(toOptions)
                    ? toOptions
                    : toOptions[objTypeValue];
                }

                return (
                  <FilterRange
                    className={styles['filter-list__item']}
                    key={`filter-${index}`}
                    name={item.name}
                    label={item.label}
                    postfix={item.postfix}
                    options={item.options}
                    min={from}
                    max={to}
                    fromOptions={fromOptions}
                    toOptions={toOptions}
                    onChange={changeFilter}
                    simple={false}
                    value={activeFilters[item.name]}
                    currency={currency}
                    isPrice={item.type === 'price'}
                    isWorkOffMe={isWorkOfficeCategory}
                    setOffMe={setOffMe}
                  />
                );
              }
              default:
                console.log(`Filters ${item.type} not found`);
            }
          })}
          <div
            className={[
              styles['filter-list__item'],
              styles['filter-list__action'],
            ].join(' ')}
          >
            <button
              className={styles['filter-item__button']}
              onClick={() => onSearch()}
            >
              <span>
                {objects.length
                  ? `${objects.length} ${declOfNumTitlePostfix}`
                  : 'Найти'}{' '}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
