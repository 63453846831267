import declOfNum from '~utils/declOfNum';

type grammarFn = (length: number) => string;
type Grammar = { [word: string]: grammarFn };

const grammar: Grammar = {
  option: (length) => declOfNum(length, ['вариант', 'варианта', 'вариантов']),
  offer: (length) =>
    declOfNum(length, ['предложение', 'предложения', 'предложений']),
  bedroom: (length) => declOfNum(length, ['спальня', 'спальни', 'спален']),
  apartment: (length) =>
    declOfNum(length, ['апартамент', 'апартамента', 'апартаментов']),
  ruble: (length) => declOfNum(length, ['рубль', 'рубля', 'рублей']),
  suitable: (length) =>
    declOfNum(length, ['подходящий', 'подходящих', 'подходящих']),
};

export default grammar;
