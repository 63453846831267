// Anti Bot Protection

/*
window.validate = (function(container) {
	'use strict';

  document.addEventListener("DOMContentLoaded", _validate);
  if (document.readyState !== 'loading') {
    _validate()
  }

  function _validate() {
    try {
      var win = window
      var navigator = win.navigator
      var document = win.document

			var container = document.createElement("div");
			document.body.appendChild(container);

      var err;
      try {
        null[0]();
      } catch (error) {
        err = error;
      }

      var invalid = false
        // Ошибки в коде, имеют stacktrace в котором можно отследить phantom, сделаем ошибку и проверим
        || ((err.stack || '').indexOf('phantom') > -1)
        // Phantomjs как правило, не имеет внутри себя браузерных плагинов
        || (!(navigator.plugins instanceof PluginArray)) // || navigator.plugins.length === 0)
        // PhantomJs 1.x прокидывает 2 свойства в глобальный объект, проверим их
        || (win.callPhantom || win._phantom)
        // PhantomJs и многие другие боты не
        || (!Function.prototype.bind)
        // Запуская код через nodejs, у window будет доступен Buffer
        || (win.Buffer !== undefined)
        // Запуская сайт через coachjs у window будет доступен emit
        || (win.emit !== undefined)
        // Запуская сайт через rhino - у window будет доступен spawn
        || (win.spawn !== undefined)
        // Не только селениум использует webdriver, но и другие боты
        || (win.webdriver !== undefined)
        // Проверяем chrome based dom automation - также инструмент ботов для работы с DOM
        || (win.domAutomation !== undefined || win.domAutomationController !== undefined)
        // Headless браузеры не имеют размера окна, проверим это
        // WARNING: эту проверку не пройдут IOS 8 и ниже, а также sailfish webview
        || (win.outerWidth === 0 && win.outerHeight === 0)
        // Множество ботов не заботится проставлении navigator.online и ходят по сайту в оффлайн режиме
        || (navigator.onLine !== true)

      if (!invalid) {
        return
      }

      // load secret image

      var backgroundImage = '/favicon.png?id=' + (((Math.random() * 10000) | 0) * 17)
      var styleSheet = document.styleSheets[document.styleSheets.length - 1]
      var ruleIndex = styleSheet.insertRule(
        'html { background-repeat: no-repeat !important; background-size: 1px 1px !important; background-position: -1px -1px !important; background-image: url(' + backgroundImage + ') !important}',
        styleSheet.cssRules.length
      )
      var rule = styleSheet.cssRules[ruleIndex]

      setTimeout(() => {
        try {
          for (var i = styleSheet.cssRules.length - 1; i >= 0; i++) {
            if (styleSheet.cssRules[i] === rule) {
              styleSheet.deleteRule(i);
              break
            }
          }
        } catch {
          // hide all errors
        }
      }, 100)
    } catch {
      // hide all errors
    }
  }
})
*/

export default function() {
  try {
    const encryptDecrypt = function(name: string) {
      return name
        .split('')
        .map((o) => o.charCodeAt(0) ^ 5)
        .map((o) => String.fromCharCode(o))
        .join('');
    };
    // console.log('validate request');
    // Use https://try.terser.org/ and getStr() for encrypt/decrypt strings
    // window.eval('...validation code...')
    // encryptDecrypt('Function');
    // encryptDecrypt(
    //   `"use strict";function e(){try{var t,e=window,n=e.navigator,o=e.document,i=o.createElement("div");o.body.appendChild(i);try{null[0]()}catch(e){t=e}if(!((t.stack||"").indexOf("phantom")>-1||!(n.plugins instanceof PluginArray)||e.callPhantom||e._phantom||!Function.prototype.bind||void 0!==e.Buffer||void 0!==e.emit||void 0!==e.spawn||void 0!==e.webdriver||void 0!==e.domAutomation||void 0!==e.domAutomationController||0===e.outerWidth&&0===e.outerHeight||!0!==n.onLine))return;var a="/favicon.png?id="+17*(1e4*Math.random()|0),r=o.styleSheets[o.styleSheets.length-1],d=r.insertRule("html { background-repeat: no-repeat !important; background-size: 1px 1px !important; background-position: -1px -1px !important; background-image: url("+a+") !important}",r.cssRules.length),u=r.cssRules[d];setTimeout((()=>{try{for(var t=r.cssRules.length-1;t>=0;t++)if(r.cssRules[t]===u){r.deleteRule(t);break}}catch{}}),100)}catch{}}document.addEventListener("DOMContentLoaded",e),"loading"!==document.readyState&&e()`,
    // );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[encryptDecrypt('Cpkfqljk')](
      't',
      encryptDecrypt(
        "'pv`%vqwlfq'>cpkfqljk%`-,~qw|~sdw%q)`8rlkajr)k8`+kdslbdqjw)j8`+ajfph`kq)l8j+fw`dq`@i`h`kq-'als',>j+gja|+duu`kaFmlia-l,>qw|~kpii^5X-,xfdqfm-`,~q8`xlc-$--q+vqdfnyy'',+lka`}Jc-'umdkqjh',;(4yy$-k+uipblkv%lkvqdkf`jc%UipblkDwwd|,yy`+fdiiUmdkqjhyy`+Zumdkqjhyy$Cpkfqljk+uwjqjq|u`+glkayysjla%5$88`+Gpcc`wyysjla%5$88`+`hlqyysjla%5$88`+vudrkyysjla%5$88`+r`gawls`wyysjla%5$88`+ajhDpqjhdqljkyysjla%5$88`+ajhDpqjhdqljkFjkqwjii`wyy5888`+jpq`wRlaqm##5888`+jpq`wM`lbmqyy$5$88k+jkIlk`,,w`qpwk>sdw%d8'*cdslfjk+ukb:la8'.42/-4`1/Hdqm+wdkajh-,y5,)w8j+vq|i`Vm``qv^j+vq|i`Vm``qv+i`kbqm(4X)a8w+lkv`wqWpi`-'mqhi%~%gdfnbwjpka(w`u`dq?%kj(w`u`dq%$lhujwqdkq>%gdfnbwjpka(vl`?%4u}%4u}%$lhujwqdkq>%gdfnbwjpka(ujvlqljk?%(4u}%(4u}%$lhujwqdkq>%gdfnbwjpka(lhdb`?%pwi-'.d.',%$lhujwqdkqx')w+fvvWpi`v+i`kbqm,)p8w+fvvWpi`v^aX>v`qQlh`jpq---,8;~qw|~cjw-sdw%q8w+fvvWpi`v+i`kbqm(4>q;85>q..,lc-w+fvvWpi`v^qX888p,~w+a`i`q`Wpi`-q,>gw`dnxxfdqfm~xx,)455,xfdqfm~xxajfph`kq+daa@s`kqIlvq`k`w-'AJHFjkq`kqIjda`a')`,)'ijdalkb'$88ajfph`kq+w`da|Vqdq`##`-,",
      ),
    )();
  } catch {
    // hide all errors
  }
}
