import React, { useState, useRef, useEffect } from 'react';
import { Check } from 'styled-icons/boxicons-regular/Check';
import { CaretDown } from 'styled-icons/boxicons-regular/CaretDown';
import getFormattedPrice from '~utils/getFormattedPrice';
import styles from './styles.module.css';

const FilterRange = ({
  value = [0, null],
  simple = false,
  name,
  isPrice,
  currency,
  fromOptions,
  toOptions,
  className,
  label,
  onChange,
  setOffMe,
  isWorkOffMe,
}) => {
  const [open, setOpen] = useState(false);
  const [stateValue, setStateValue] = useState(value);
  const [inputFocusedId, setInputFocusedId] = useState(0);
  
  useEffect(() => {
    setStateValue(value)
  }, [value])

  const filterRef = useRef(null);

  const [valueFrom, valueTo] = stateValue;

  const onFromInputChange = ({ target: { value, name } }) => {
    const valueFrom = parseInt(value) || 0;
    const newValue = [valueFrom, valueTo];

    setStateValue(newValue);

    if (onChange) {
      onChange(name, newValue);
    }
  };

  const onToInputChange = ({ target: { value, name } }) => {
    const valueTo = parseInt(value) || null;
    const newValue = [valueFrom, valueTo];

    setStateValue(newValue);

    if (onChange) {
      onChange(name, newValue);
    }
  };

  const onFromOptionClick = (value, e) => {
    e.stopPropagation();

    const valueFrom = parseInt(value) || 0;
    const newValue = [valueFrom, valueTo];

    setStateValue(newValue);
    setInputFocusedId(1);

    if (onChange) {
      onChange(name, newValue);
    }
  };

  const onToOptionClick = (value, e) => {
    e.stopPropagation();

    const valueTo = parseInt(value) || null;
    const newValue = [valueFrom, valueTo];

    setStateValue(newValue);
    setInputFocusedId(0);
    setOpen(false);

    if (onChange) {
      onChange(name, newValue);
    }
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };

  const onInputFocus = (e, inputId) => {
    setInputFocusedId(inputId);
  };

  const onSelectClick = (e) => {
    e.stopPropagation();
    isWorkOffMe && setOffMe();
    setOpen((open) => !open);

    const windowClickHandler = (event) => {
      if (event.target !== filterRef.current) {
        setOpen(false);
        window.removeEventListener('click', windowClickHandler);
      }
    };

    window.addEventListener('click', windowClickHandler);
  };

  const classes = [styles['filter-range']];

  if (className) classes.push(className);
  if (simple) classes.push(styles['filter-range__no-label']);
  if (open) classes.push(styles['filter-range_open']);

  const valueFromStr = `от ${
    isPrice ? getFormattedPrice({ price: valueFrom, currency }) : valueFrom
  }`;

  const valueToStr =
    valueTo === null
      ? ''
      : `до  ${
          isPrice ? getFormattedPrice({ price: valueTo, currency }) : valueTo
        }`;

  const isFromInputFocused = inputFocusedId === 0;
  const isToInputFocused = inputFocusedId === 1;

  return (
    <div className={classes.join(' ')} onClick={onSelectClick}>
      {!simple && (
        <label className={styles['filter-range__label']}>{label}:</label>
      )}
      <div className={styles['filter-range__current']}>
        <div className={styles['filter-range__current-label']}>
          {valueFromStr} {valueToStr}
        </div>
        <CaretDown color='#78c810' size={15} />
      </div>
      <div className={styles['filter-range__options']}>
        <div className={styles['filter-range__inputs']}>
          <input
            tabIndex='1'
            maxLength='11'
            value={valueFrom || ''}
            type='text'
            placeholder='мин'
            name={name}
            onFocus={(e) => onInputFocus(e, 0)}
            onClick={onInputClick}
            onChange={onFromInputChange}
          />
          <input
            tabIndex='1'
            maxLength='11'
            value={valueTo || ''}
            type='text'
            placeholder='макс'
            name={name}
            onFocus={(e) => onInputFocus(e, 1)}
            onClick={onInputClick}
            onChange={onToInputChange}
          />
        </div>

        {open &&
          isFromInputFocused &&
          fromOptions.map((item, index) => {
            const classes = [
              styles['filter-range__option'],
              styles['filter-range__option-min'],
            ];
            const isActive = item.value === valueFrom;

            if (isActive) classes.push(styles['filter-range__option-active']);

            return (
              <div
                className={classes.join(' ')}
                key={`select-${index}`}
                onClick={(e) => onFromOptionClick(String(item.value), e)}
              >
                <span>{item.label}</span>
                {isActive && <Check color='#fff' size={20} />}
              </div>
            );
          })}

        {open &&
          isToInputFocused &&
          toOptions.map((item, index) => {
            const classes = [
              styles['filter-range__option'],
              styles['filter-range__option-max'],
            ];
            const isActive = item.value === valueTo;
            const newValue =
              item.value !== null ? String(item.value) : item.value;

            if (isActive) classes.push(styles['filter-range__option-active']);

            return (
              <div
                className={classes.join(' ')}
                key={`select-${index}`}
                onClick={(e) => onToOptionClick(newValue, e)}
              >
                {isActive && <Check color='#fff' size={20} />}
                <span>{item.label}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FilterRange;
