import fetchJSONP from '~utils/fetchJSONP';
import buildUrl from '~utils/buildUrl';
import { URLS } from '~src/api/urls';
import { CurrencyType } from '~constants/currencyMap';
import { GetOptions, CommonResponse } from './types';
import { ObjectType } from '~entities/Object';
import { Complex } from '~entities/Complex';

export type Query = {
  areaFrom?: number;
  areaTo?: number;
  complexId?: Complex['id'];
  floorFrom?: number;
  floorTo?: number;
  'furnish[]'?: ObjectType['furnish'];
  'rooms[]'?: number[];
  type?: ObjectType['type'];
  typeListing?: ObjectType['type_listing'] | 'all';
  currency?: CurrencyType;
  costFrom?: number;
  costTo?: number;
  costmFrom?: number;
  costmTo?: number;
};

export type ResponseData = {
  items: ObjectType[];
  rents: string;
  sales: string;
};

type Response = CommonResponse<ResponseData>;

export default function getObjects({ query }: GetOptions<Query>) {
  return fetchJSONP<Response>(buildUrl(URLS.old.getObjects, query));
}
