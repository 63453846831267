import { stringify } from 'query-string';
import { Query } from '~src/api/get/getObjects';
import validate from './validate';

validate();

export const URLS = {
  old: {
    visitOffice: 'https://www.mcity.ru/visit-sales-office',
    getObjects: `https://www.mcity.ru/api/get-objects`,
    getPhotos: `https://www.mcity.ru/api/get-photos`,
    getFilter: 'https://www.mcity.ru/api/get-filter',
    getComplexes: 'https://www.mcity.ru/api/towers',
    getFeeds: 'https://crm.mcity.ru/feeds',
    getBrokerListing: 'https://crm.mcity.ru/api/list',
    feedsContactForm: 'https://crm.mcity.ru/feeds/order',
    subscription: 'https://www.mcity.ru/api/add-office-subscribers',
    callme: `https://www.mcity.ru/callme`,
    moreInfo: 'https://www.mcity.ru/more-info',
    quizContactForm: 'https://www.mcity.ru/quiz-form',
    postLikeUrl: 'https://crm.mcity.ru/api/like',
    getSearchUrl: (query: Query) =>
      `https://www.mcity.ru/search?${stringify(query)}`,
    getPrezFormUrl: (objectUrl: string) =>
      `https://www.mcity.ru/object/prez/${objectUrl}`,
    getObjectPreviewFormUrl: (objectUrl: string) =>
      `https://www.mcity.ru/object/${objectUrl}`,
  },

  v1: {
    visitOffice: '/api/sales_office', // apartments.mcity.ru
    getObjects: `https://www.mcity.ru/api/get-objects`,
    getPhotos: `https://www.mcity.ru/api/get-photos`,
    getFilter: 'https://www.mcity.ru/api/get-filter',
    getComplexes: 'https://www.mcity.ru/api/towers',
    getFeeds: 'https://crm.mcity.ru/feeds',
    getBrokerListing: 'https://crm.mcity.ru/api/list',
    feedsContactForm: 'https://crm.mcity.ru/feeds/order',
    subscription: '/api/email_subscribe', // invest.mcity.ru, office.mcity.ru
    callMe: `/api/call_request`, // apartments.mcity.ru
    callMeWithName: `/api/call_request`, // invest.mcity.ru, apartments.mcity.ru, office.mcity.ru
    moreInfo: '/api/discount',
    rentCalculatorContactForm: '/api/calc', // invest.mcity.ru, office.mcity.ru
    buyResultsStepContactForm: '/api/quiz_buy', // invest.mcity.ru
    rentResultsStepContactForm: '/api/quiz_rent', // invest.mcity.ru, office.mcity.ru
    investResultsStepContactForm: '/api/quiz_investment', // invest.mcity.ru
    apartmentsResultsStepContactForm: '/api/quiz', // apartments.mcity.ru
    searchContactForm: '/api/catalog', // office.mcity.ru
    postLikeUrl: 'https://crm.mcity.ru/api/like',
    prezForm: '/api/pdf',
    objectPreviewForm: '/api/watch',
    getSearchUrl: (query: Query) =>
      `https://www.mcity.ru/search?${stringify(query)}`,
    getPrezFormUrl: (objectUrl: string) =>
      `https://www.mcity.ru/object/prez/${objectUrl}`,
    getObjectPreviewFormUrl: (objectUrl: string) =>
      `https://www.mcity.ru/object/${objectUrl}`,
  },

  v2: {
    callMe: `/api/call_request`,
    moreInfo: '/api/discount',
    previewForm: '/api_v2/watch',
    objectPreviewForm: '/api_v2/watch_object',
    prezForm: '/api_v2/pdf',
    objectPrezForm: '/api_v2/pdf_object',
    contact: '/api_v2/contact',
    contactFromCalc: '/api_v2/calc',
  },
};
