import { stringify, StringifyOptions } from 'query-string';

export default function buildUrl(
  pathname: string,
  //eslint-disable-next-line ~typescript-eslint/no-explicit-any
  query: Record<string, any>,
  qsOptions?: StringifyOptions,
): string {
  const queryAsString = stringify(query, qsOptions);

  return queryAsString.length ? `${pathname}?${queryAsString}` : pathname;
}
