import fetchJSONP from '~utils/fetchJSONP';
import buildUrl from '~utils/buildUrl';
import { URLS } from '~src/api/urls';
import { ObjectType } from '~entities/Object';
import { GetOptions, CommonResponse } from './types';

type Query = {
  idd: ObjectType['id_crm'];
};

type Data = {
  previews: string[];
  images: string[];
  plans?: string[];
};

type Response = CommonResponse<Data>;

export default function getPhotos({ query }: GetOptions<Query>) {
  return fetchJSONP<Response>(buildUrl(URLS.old.getPhotos, query));
}
