import React, { Component, createContext } from 'react';
import { get, isEqual } from 'lodash';
import autobind from 'autobind-decorator';

export const FilterContext = createContext();

class Filter extends Component {
  constructor(props) {
    super(props);
    const filtersStructure = Filter.initFilters(props.filters);

    this.state = { ...filtersStructure, manuallyChangedFilterNames: new Set() };
  }

  componentWillReceiveProps(nextProps) {
    const nextFilters = nextProps[Filter.FILTERS];
    const filters = this.props[Filter.FILTERS];

    if (!isEqual(filters, nextFilters)) {
      const filtersStructure = Filter.initFilters(nextFilters);
      this.setState({ ...filtersStructure });
    }
  }

  componentDidMount() {
    if (this.props.shouldUpdateOnMount) {
      this.updateFilters();
    }
  }

  static FILTERS = 'filters';
  static OBJECTS_TYPE = 'type';
  static CATEGORY = 'typeListing';
  static MULTI_FILTERS_STATE_NAME = 'multiFilters';
  static ACTIVE_FILTERS_STATE_NAME = 'activeFilters';
  static SIMPLE_FILTERS_STATE_NAME = 'simpleFilters';
  static DEFAULT_FILTERS_STATE_NAME = 'defaultFilters';

  static initFilters(dirtyFilters = []) {
    let temp = [];
    let activeFilters = {};
    let defaultFilters = {};
    let simpleFilters = [];
    let multiFilters = {};

    for (let i = 0; i < dirtyFilters.length; i++) {
      const filter = dirtyFilters[i];

      if (filter.type === 'multiControl') {
        if (Array.isArray(filter.controls) && filter.controls.length) {
          for (let j = 0; j < filter.controls.length; j++) {
            temp.push(filter.controls[j]);
          }
          multiFilters[filter.multiControlName] = filter.active;
        }
      } else {
        temp.push(filter);
        simpleFilters.push(filter.name);
      }
    }

    for (let i = 0; i < temp.length; i++) {
      const { type, name, from, to, min, max, nameFrom, nameTo } = temp[i];
      let { value } = temp[i];

      // TODO: Fallback, if server no return value for filter items
      switch (type) {
        case 'yesNo':
        case 'multiSwitch':
        case 'multiSelect':
          value = Array.isArray(value) ? value : [];
          break;
        case 'select':
        case 'switch':
          value = value || '';
          break;
        case 'price':
        case 'range': {
          const objTypeFilter = temp.find(
            (filter) => Filter.OBJECTS_TYPE === filter.name,
          );

          if (objTypeFilter) {
            const { value: objTypeDefaultVal } = objTypeFilter;

            const fromVal = get(from, objTypeDefaultVal, from);
            const toVal = get(to, objTypeDefaultVal, to);

            value = [fromVal, toVal];
          } else {
            value = [from, to];
          }

          break;
        }

        default:
          break;
      }

      // For we dont need added property in url if filter type does not supported
      switch (type) {
        case 'yesNo':
        case 'multiSwitch':
        case 'multiSelect':
        case 'select':
        case 'switch':
          activeFilters[name] = value;
          break;
        case 'range':
        case 'price': {
          activeFilters[name] = value;
          defaultFilters[name] = { type, min, max, nameFrom, nameTo, from, to };
          break;
        }
        default:
          console.log(`Filter ${type} not supported`);
          break;
      }
    }

    return {
      [Filter.ACTIVE_FILTERS_STATE_NAME]: activeFilters,
      [Filter.DEFAULT_FILTERS_STATE_NAME]: defaultFilters,
      [Filter.SIMPLE_FILTERS_STATE_NAME]: simpleFilters,
      [Filter.MULTI_FILTERS_STATE_NAME]: multiFilters,
    };
  }

  @autobind
  getFiltersObject() {
    const activeFilters = this.state[Filter.ACTIVE_FILTERS_STATE_NAME];
    let temp = {};

    for (let prop in activeFilters) {
      if (activeFilters.hasOwnProperty(prop)) {
        if (temp.hasOwnProperty(prop)) {
          if (Array.isArray(activeFilters[prop])) {
            temp[prop] = temp[prop].concat(activeFilters[prop]);
          } else {
            temp[prop].push(activeFilters[prop]);
          }
        } else {
          temp[prop] = Array.isArray(activeFilters[prop])
            ? activeFilters[prop]
            : activeFilters[prop];
        }
      }
    }

    return temp;
  }

  @autobind
  getFiltersQuery() {
    const defaultFilters = this.state[Filter.DEFAULT_FILTERS_STATE_NAME];
    const temp = this.getVisibleActiveFilters();
    for (let key in defaultFilters) {
      if (
        temp.hasOwnProperty(key) &&
        defaultFilters.hasOwnProperty(key) &&
        (defaultFilters[key].type === 'range' ||
          defaultFilters[key].type === 'price')
      ) {
        const { min, max, nameFrom, nameTo } = defaultFilters[key];
        const tempValueFrom = temp[key][0];
        const tempValueTo = temp[key][1] === null ? max : temp[key][1];
        const valueFrom =
          tempValueFrom > tempValueTo ? tempValueTo : tempValueFrom;
        const valueTo =
          tempValueFrom > tempValueTo ? tempValueFrom : tempValueTo;

        if (
          valueFrom !== 0 &&
          valueFrom !== '' &&
          valueFrom !== 'none' &&
          valueFrom !== min
        ) {
          temp[nameFrom] = valueFrom;
        }

        if (
          valueTo !== 0 &&
          valueTo !== '' &&
          valueTo !== 'none' &&
          valueTo !== max &&
          valueTo !== null
        ) {
          temp[nameTo] = valueTo;
        }

        delete temp[key];
      }
    }

    return temp;
  }

  @autobind
  getVisibleActiveFilters() {
    const simpleFilters = this.state[Filter.SIMPLE_FILTERS_STATE_NAME];
    const multiFilters = this.state[Filter.MULTI_FILTERS_STATE_NAME];
    const visibleFilters = simpleFilters.concat(
      Object.keys(multiFilters).map((item) => multiFilters[item]),
    );

    let temp = this.getFiltersObject();

    for (let prop in temp) {
      if (temp.hasOwnProperty(prop)) {
        if (visibleFilters.indexOf(prop) === -1) {
          delete temp[prop];
        }

        if (Array.isArray(temp[prop]) && !temp[prop].length) {
          delete temp[prop];
        }
      }
    }

    return temp;
  }

  @autobind
  deformatFilterList(filters) {
    let temp = [];
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === 'multiControl') {
        const subFilters = this.deformatFilterList(filters[i].controls);
        temp = temp.concat(subFilters);
      } else {
        temp.push(filters[i]);
      }
    }
    return temp;
  }

  @autobind
  getFilters() {
    return this.state[Filter.FILTERS];
  }

  @autobind
  getActiveFilters() {
    return this.state[Filter.ACTIVE_FILTERS_STATE_NAME];
  }

  @autobind
  getDefaultFilters() {
    return this.state[Filter.DEFAULT_FILTERS_STATE_NAME];
  }

  @autobind
  getMultiFilters() {
    return this.state[Filter.MULTI_FILTERS_STATE_NAME];
  }

  @autobind
  getCategory() {
    const activeFilters = this.state[Filter.ACTIVE_FILTERS_STATE_NAME];
    // console.log('activeFilters:',activeFilters)
    return activeFilters.hasOwnProperty(Filter.CATEGORY)
      ? activeFilters[Filter.CATEGORY]
      : /* this.props.config.catalog.category ||  */ null;
  }

  @autobind
  changeMultipleFilter(name, value) {
    const activeFilters = this.state[Filter.ACTIVE_FILTERS_STATE_NAME];
    const values = activeFilters[name];
    const index = values.indexOf(value);

    index !== -1 ? values.splice(index, 1) : values.push(value);

    this.changeFilter(name, values);
  }

  @autobind
  changeFilter(name, value) {
    this.setState(
      {
        manuallyChangedFilterNames: new Set([
          ...this.state.manuallyChangedFilterNames,
          name,
        ]),
      },
      this.updateFiltersState(name, value),
    );
  }

  @autobind
  changeMultiControlFilters(index, name) {
    this.updateMultiFiltersState(index, name);
  }

  @autobind
  updateFiltersState(name, value) {
    this.setState((prevState) => {
      const resetFilters = [Filter.OBJECTS_TYPE, Filter.CATEGORY];
      let newState = resetFilters.includes(name)
        ? this.resetFiters(
            prevState[Filter.ACTIVE_FILTERS_STATE_NAME],
            name,
            value,
          )
        : prevState[Filter.ACTIVE_FILTERS_STATE_NAME];
      return {
        [Filter.ACTIVE_FILTERS_STATE_NAME]: {
          ...newState,
          [name]: value,
        },
      };
    }, this.updateFilters);
  }

  @autobind
  resetFiters(state, changedFilterName, changedFilterValue) {
    const newState = { ...state };
    const defaultFilters = this.state[Filter.DEFAULT_FILTERS_STATE_NAME];

    for (let prop in newState) {
      if (state.hasOwnProperty(prop)) {
        switch (prop) {
          case 'furniture[]':
          case 'furnish[]':
          case 'complexId[]':
            newState[prop] = [];
            break;

          case 'area': {
            if (
              this.state.manuallyChangedFilterNames.has('area') ||
              changedFilterName !== Filter.OBJECTS_TYPE
            ) {
              break;
            }

            if (prop in defaultFilters) {
              let { from, to } = defaultFilters[prop];
              from = get(from, changedFilterValue, from);
              to = get(to, changedFilterValue, to);
              newState[prop] = [from, to];
            } else {
              newState[prop] = [0, null];
            }
            break;
          }
          case 'price':
          case 'priceFull': {
            if (prop in defaultFilters) {
              let { min, max, from, to } = defaultFilters[prop];
              min = get(min, changedFilterValue, min);
              max = get(max, changedFilterValue, max);
              from = get(from, changedFilterValue, from);
              to = get(to, changedFilterValue, to);
              newState[prop] = [from || min, to || max];
            } else {
              newState[prop] = [0, null];
            }
            break;
          }
          default:
            break;
        }
      }
    }
    return newState;
  }

  @autobind
  updateMultiFiltersState(name, value) {
    this.setState(
      (prevState) => ({
        [Filter.MULTI_FILTERS_STATE_NAME]: {
          ...prevState[Filter.MULTI_FILTERS_STATE_NAME],
          [name]: value,
        },
      }),
      this.updateFilters,
    );
  }

  @autobind
  updateFilters() {
    const { updateFilters } = this.props;
    const query = this.getFiltersQuery();

    if (typeof updateFilters === 'function') updateFilters(query);
  }

  @autobind
  onSearch() {
    const { onSearch } = this.props;
    const query = this.getFiltersQuery();

    if (typeof onSearch === 'function') onSearch(query);
  }

  render() {
    const { filters } = this.props;

    const value = {
      filters,
      getCategory: this.getCategory,
      getMultiFilters: this.getMultiFilters,
      getActiveFilters: this.getActiveFilters,
      getDefaultFilters: this.getDefaultFilters,
      getVisibleActiveFilters: this.getVisibleActiveFilters,
      getFiltersQuery: this.getFiltersQuery,
      deformatFilterList: this.deformatFilterList,
      changeFilter: this.changeFilter,
      changeMultipleFilter: this.changeMultipleFilter,
      changeMultiControlFilters: this.changeMultiControlFilters,
      onSearch: this.onSearch,
    };
    // console.log(value);

    return (
      <FilterContext.Provider value={value}>
        {this.props.children}
      </FilterContext.Provider>
    );
  }
}

export default Filter;
